.trip-container {
  margin: 8px;
  width: 250px;
  padding: 8px;
}

.trip-container:hover {
  filter: brightness(0.8);
  background: white;
  cursor: pointer;
}

.trip-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.trip-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}