.login-container {
  background-image: url('login-background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.login-card {
  background-color: white;
  padding: 1em;
  width: 30em;
  margin: 10em auto auto auto;
  border-radius: 10px;
}