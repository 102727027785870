.container {
  padding: 1em;
}

.back-button {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: cornflowerblue;
  padding: 6px;
  display: inline-block;
  font-size: 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 28px;
  background: white;
  z-index: 100;
}

.back-button:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.trip-card-container {
  display: flex;
  flex-wrap: wrap;
}