.ES-map {
  width: 70%;
  height: 100vh;
  display: inline-block;
}

.guide-details {
  width: 70%;
  height: 100vh;
  overflow: auto;
  position: absolute;
  z-index: 1000;
  background: white;
}

.guide-list {
  width: 30%;
  height: 100vh;
  overflow: auto;
  display: inline-block;
}

@media only screen and (max-width: 768px) {

  .ES-map {
    width: 100%;
    height: 70vh;
  }

  .guide-details {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    position: absolute;
    z-index: 1000;
    background: white;
  }

  .guide-list {
    width: 100%;
    height: 30vh;
    overflow: scroll;
  }

}
