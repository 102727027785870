.guide-description {
  display: flex;
  padding: 10px;
}

.guide-description:hover {
  background: #eaeaea;
  cursor: pointer;
}

.guide-image {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
}

.guide-name {
  display: inline;
  margin-left: 10px;
}

.title {
  margin: 10px;
}

.guide-email {
  margin: 5px 0 5px 10px;
  font-size: 14px;
  color: #666;
}

.verified-icon {
  color: green;
  padding: 0 5px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 135%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
  box-sizing: inherit;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}